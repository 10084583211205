<template>
  <div>
    <c-search-box @enter="getMap">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-datepicker
            :range="true"
            defaultStart="-6M"
            defaultEnd="6M"
            label="작업기간"
            name="workDts"
            v-model="searchParam.workDts"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            :editable="editable"
            type="search"
            :comboItems="dangerFlagItems"
            itemText="codeName"
            itemValue="code"
            label="위험작업여부"
            name="dangerFlag"
            v-model="searchParam.dangerFlag">
          </c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            :editable="editable"
            type="search"
            :comboItems="useFlagItems"
            itemText="codeName"
            itemValue="code"
            label="사용여부"
            name="useFlag"
            v-model="searchParam.useFlag">
          </c-select>
        </div>
      </template>
    </c-search-box>
    <div class="col-xs-12 col-sm-12 col-md-9 col-lg-9 col-xl-9">
      <c-card :title="worktitle" class="cardClassDetailForm" :noMarginPadding="true" :height="imgHeight"> 
        <template slot="card-button">
          <q-btn-group outline >
            <c-btn v-if="editable" label="지도 변경" icon="place" @btnClicked="selectMap" />
            <c-btn label="검색" icon="search" @btnClicked="getMap" />
          </q-btn-group>
        </template>
        <template slot="card-detail">
          <div class="col-12">
            <div id="parent" ref="parent">
              <div v-if="setComplete && mapdata.length > 0" :style="{'height': imgHeight}">
                <VueDraggableResizable
                  v-for="(item, idx) in mapdata"
                  :key="idx"
                  ref="markImage"
                  class="my-class"
                  :parent="true"
                  :draggable="false"
                  :resizable="false"
                  class-name-dragging="my-dragging-class"
                  :x="item.locationX"
                  :y="item.locationY"
                  :w="item.locationW" 
                  :h="item.locationH"
                  :minHeight="20" :minWidth="20"
                >
                <div class="loc-header">{{item.workName}}</div>
                <q-icon v-if="item.dangerFlag == 'Y'" name="warning" style="color: red;" size="md"  />
                <q-tooltip anchor="top middle" self="top start">
                  작업명 : {{item.workName}} <br>
                  작업기간 : {{item.workStartDt}} ~ {{item.workEndDt}} <br>
                  작업업체 : {{item.vendorName}} <br>
                  작업인원 : {{item.workers}} 명
                </q-tooltip>
                </VueDraggableResizable>
              </div>
            </div>
          </div>
        </template>
      </c-card>
      <c-dialog :param="popupOptions"></c-dialog>
    </div>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import VueDraggableResizable from 'vue-draggable-resizable'
import 'vue-draggable-resizable/dist/VueDraggableResizable.css'
export default {
  name: "work-location",
  components: { VueDraggableResizable },
  data() {
    return {
      worktitle: '작업 위치',
      slide: 0,
      setComplete: false,
      mapSrc: require('@/assets/images/no-image.png'),
      imgHeight: '',
      searchParam: {
        plantCd: null,
        useFlag: 'Y',
        workDts: [],
        dangerFlag: null,
        sopMapId: '',
        haveMap: 'Y'
      },
      useFlagItems: [ // 사용여부
        { code: 'Y', codeName: '사용' },
        { code: 'N', codeName: '미사용' },
      ],
      dangerFlagItems: [ // 위험작업여부
        { code: 'Y', codeName: '위험작업' },
        { code: 'N', codeName: '일반작업' },
      ],
      editable: true,
      listUrl: '',
      recentUrl: '',
      isResult: false,
      mapdata: [],
      ratio: null,
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        top: '10px',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {},
  methods: {
    init() {
      this.imgHeight = String(window.innerHeight - 250) + 'px'
      this.editable = this.$route.meta.editable;
      this.recentUrl = selectConfig.sop.swp.map.recent.url
      this.listUrl = selectConfig.sop.work.loc.list.url;
      this.getMap();
    },
    getMap() {
      this.$http.url = this.recentUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        useFlag: 'Y',
        plantCd: this.searchParam.plantCd
      };
      this.$http.request((_result) => {
        let sopid = _result.data.sopMapId;
        this.worktitle = '작업 위치 (' + _result.data.mapName + ')'
        if (_result.data) {
          this.$comm.previewImageParamTask(
            _result.data.sopMapId,
            'WORK_MAP'
          ).then(_result => {
            this.mapSrc = _result;
            this.setCanvas(sopid);
          });
        } else {
          window.getApp.$emit('ALERT', {
            title: '안내', // 안내
            message: '공사현장별 지도정보를 등록 후 이용하시기 바랍니다.', // 지도정보를 등록 후 이용하시기 바랍니다.
            type: 'warning', // success / info / warning / error
          });
        }
      },);
    },
    setCanvas(_sopid) {
      let image = new Image();
      let _listurl = selectConfig.sop.work.loc.list.url
      image.src = this.mapSrc;
      let thisVue = this;
      image.onload = function () {
        let parent = document.getElementById('parent');
        thisVue.ratio = image.width / image.height
        let _width = thisVue.ratio * 700

        parent.style.cssText  = 'background: linear-gradient(-90deg, rgba(0, 0, 0, 0) 0px, transparent 0px) 0% 0% / ' 
          + 10 + 'px ' + 10 + 'px, linear-gradient(rgba(0, 0, 0, 0) 0px, transparent 0px) 0% 0% / ' + 10 + 'px ' + 10 + 'px '
          + ', url("' + thisVue.mapSrc + '") no-repeat left/contain;'
          + 'overflow:auto; transform-origin: left top;'
          // + 'background-size: 800px;'
          + 'height:700px;width:' + _width + ';';
          // + 'height:100%;width:800px;';
          // + 'height:800px;width:800px';
      };

      this.$http.url = _listurl;
      this.$http.type = 'GET';
      this.searchParam.sopMapId = _sopid;
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.mapdata = _result.data;
        setTimeout(() => {
          thisVue.setComplete = true;
        }, 500);
      },);
    },
    selectMap() {
      this.popupOptions.title = '지도 검색'; // 지도 검색
      this.popupOptions.param = {
        plantCd: this.searchParam.plantCd,
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/swp/base/workMapPop.vue'}`);
      this.popupOptions.width = '70%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeMapPopup;
    },
    closeMapPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.ratio = data[0].ratio
        this.$comm.previewImage({
          sysAttachFileId: data[0].sysAttachFileId,
          contentType: data[0].contentType,
        }).then(_result => {
          this.mapSrc = _result;
          this.worktitle = '작업 위치 (' + data[0].mapName + ')'
          this.setCanvas(data[0].sopMapId);
        });
      }
    },
  },
};
</script>
<style>
.my-class {
  /* background-color: #9E9E9E; */
  border: 3px dashed #FF5722;
  -webkit-transition: background-color 200ms linear;
  -ms-transition: background-color 200ms linear;
  transition: background-color 200ms linear;
  position: absolute;
  text-align: center;
}

.my-dragging-class {
  background-color: #FF5722;
  border: 3px dashed #FF5722;
}
.loc-header {
  width: 100%;
  background: #fff;
  font-weight: 700;
}
</style>